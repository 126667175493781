
import React, { useState, useEffect } from "react"
import './App.css';
import logo from './img/logo-principal1.png';
import "./styles/home.css"
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import '@sweetalert2/theme-dark/dark.css';
import { useNavigate } from "react-router-dom";

const MySwal = withReactContent(Swal);

export const Inicio = ({ logged, setLogged, user_name, setUsername, user_lastname, setUserLastname, user_company, setUserCompany, user_email, setUserEmail }) => {
  const navigate = useNavigate();
  const [username, setUserName] = useState('')
  const [pass, setPass] = useState('')

  useEffect(() => {
    const verifyLogged = () => {
      if(logged){
        navigate('/home');
      }
    }

    verifyLogged();
  }, [logged]);

  const validateLoginForm = (data) => {
    console.log("VERIFY NUMBER");
    if (Object.values(data).includes('')) {
        MySwal.fire({
            icon: 'error',
            title: 'Woops!!',
            text: 'Completa todos los campos, por favor...',
            showConfirmButton: false,
            timer: 1500
        })

        return false;
    }

    credentialsComprobation(data);
}

const credentialsComprobation = async (data) => {
  try {
    console.log("COMENZANDO CONSUMO DE API DE VALIDACIÓN DE CREDENCIALES...");
    const response = await fetch('https://systemlite.spot1.mx/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: data.username,
        password: data.pass
      })
    })

    if (response.status == 200) {
      const responseJson = await response.json();
      console.log(responseJson, " | RESPONSE JSON | ")
      setUserCompany(responseJson.company_id);
      setUsername(responseJson.name);
      setUserLastname(responseJson.lastname);
      setUserEmail(responseJson.email);
      setLogged(true);
      navigate('/home');
      
    } else {
     // Alert.alert('Woops!', 'Al parecer no estás registrado, pide al administrador que te agregue.');
    MySwal.fire({
      icon: 'error',
      title: 'Woops!!',
      text: 'Usuario o contraseña erróneos.',
      showConfirmButton: false,
      timer: 1500
    })
    }

  } catch (error) {
    console.log(error)
    MySwal.fire({
      icon: 'error',
      title: 'Woops!!',
      text: error,
      showConfirmButton: false,
      timer: 1500
    })
    //Alert.alert('Woops!', error);
  }
}

const handleSubmit = (e) => {
  console.log(e.target.elements.username.value, " username")
  console.log(e.target.elements.password.value, " password")
  e.preventDefault(); // Evita que el formulario recargue la página
  // validateLoginForm({ username, pass }); // Llama a la función de validación
};
  return (
    <>
    <section className="banner">
      <img src={logo} className="imgBanner" />
      <div className="banner_content">
        {/* <h2>Welcome to Dynamic Dashboard</h2>
        <p>A creation from Spot 1 Mobile...</p> */}
        <div className="login-box">
          <h2>Ingresar</h2>
          <form onSubmit={handleSubmit}>
            <div className="user_box">
              <input type="text" value={username} onChange={(e) => setUserName(e.target.value)} id="username" name="username" required />
              <label>Username</label>
            </div>
            <div className="user_box">
              <input type="password" value={pass} onChange={(e) => setPass(e.target.value)} id="password" name="password" required />
              <label>Password</label>
            </div>
            <button type="submit" onClick={() => validateLoginForm({ username, pass })}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Ingresar
            </button>
          </form>
        </div>
      </div>
    </section>
    {/* <svg className="waves-svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 24 150 28"
    preserveAspectRatio="none"
  >
    <defs>
      <path
        id="gentle-wave"
        d="M-160 44c30 0 
  58-18 88-18s
  58 18 88 18 
  58-18 88-18 
  58 18 88 18
  v44h-352z"
      />
    </defs>
    <g className="waves">
      <use
        xlinkHref="#gentle-wave"
        x={50}
        y={0}
        fill="#244c7c"
        fillOpacity=".2"
      />
      <use
        xlinkHref="#gentle-wave"
        x={50}
        y={3}
        fill="#244c7c"
        fillOpacity=".5"
      />
      <use
        xlinkHref="#gentle-wave"
        x={50}
        y={6}
        fill="#244c7c"
        fillOpacity=".9"
      />
    </g>
  </svg> */}
    </>
  );
}


