export const getTokenAltan = async () => {
    let resp;
    try {
        const response = await fetch('https://apps-ws.spot1.mx/api/api-router', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "api": "getAccessToken"
            })
        })

        const responseJson = await response.json()

        if (response.status == 200) {
            responseJson.error = false;
            return responseJson;

        } else {
            responseJson.error = true;
            responseJson.error_desc = "BAD STATUS ["+ response.status+"]";
            return responseJson;
        }
    } catch (error) {
        resp.error = true;
        resp.error_desc = error;
        return resp;
    }
}

export const consultUFAPI = async (data) => {
    let resp;
    try {
        const response = await fetch('https://apps-ws.spot1.mx/api/api-router', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "api": "consultUF",
                "data_request": {
                    accessToken: data.accessToken,
                    msisdn: data.msisdn
                }
            })
        })

        const responseJson = await response.json()

        if (response.status == 200) {
            responseJson.error = false;
            return responseJson;

        } else {
            responseJson.error = true;
            responseJson.error_desc = "BAD STATUS ["+ response.status+"]";
            return responseJson;
        }
    } catch (error) {
        resp.error = true;
        resp.error_desc = error;
        return resp;
    }
}

export const getRatesActivation = async data => {
    let resp;
    try {
        const response = await fetch('https://mobile.spot1.mx/api/get-company-rates', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                company_id: data.company_id
            })
        })

        const responseJson = await response.json();

        if (response.status == 200) {
            responseJson.error = false;
            return responseJson;
        } else {
            responseJson.error = true;
            responseJson.error_desc = "BAD STATUS ["+ response.status+"]";
            return responseJson;
        }

        //   console.log(responseJson, 'RATES')
    } catch (error) {
        resp.error = true;
        resp.error_desc = error;
        return resp;
    }
}

export const getRatesRecharge = async data => {
    let resp;
    try {
        const response = await fetch('https://mobile.spot1.mx/api/get-company-rates', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                company_id: data.company_id
            })
        })

        const responseJson = await response.json();

        if (response.status == 200) {
            responseJson.error = false;
            return responseJson;
        } else {
            responseJson.error = true;
            responseJson.error_desc = "BAD STATUS ["+ response.status+"]";
            return responseJson;
        }

        //   console.log(responseJson, 'RATES')
    } catch (error) {
        resp.error = true;
        resp.error_desc = error;
        return resp;
    }
}

export const getNumberByIcc = async (data) => {
    let resp;
    try {
        const response = await fetch('https://apps-ws.spot1.mx/get-number-by-icc', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                icc: data.icc + 'F'
            })
        })

        const responseJson = await response.json()

        if (response.status == 200) {
            responseJson.error = false;
            return responseJson;
        } else {
            responseJson.error = true;
            responseJson.error_desc = "BAD STATUS ["+ response.status+"]";
            return responseJson;
        }

    } catch (error) {
        resp.error = true;
        resp.error_desc = error;
        return resp;
    }
}

export const activateMSISDN = async (data) => {
    let resp;
    try {
        const response = await fetch('https://apps-ws.spot1.mx/api/api-router', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "api": "activate_msisdn_SYSTEM_LITE",
                "data_request": {
                    accessToken: data.accessToken,
                    msisdn: data.msisdn,
                    offeringId: data.offerID,
                    rate_id: data.rate_id
                }
            })
        })

        const responseJson = await response.json()

        if (response.status == 200) {
            responseJson.error = false;
            return responseJson;
        } else {
            responseJson.error = true;
            responseJson.error_desc = "BAD STATUS ["+ response.status+"]";
            return responseJson;
        }

    } catch (error) {
        resp.error = true;
        resp.error_desc = error;
        return resp;
    }
}

export const barring = async (data) => {
    let resp;
    try {
        const response = await fetch('https://apps-ws.spot1.mx/api/api-router', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "api": "suspendTraficEnt",
                "data_request": {
                    accessToken: data.accessToken,
                    msisdn: data.msisdn
                }
            })
        })

        const responseJson = await response.json()

        if (response.status == 200) {
            responseJson.error = false;
            return responseJson;
        } else {
            responseJson.error = true;
            responseJson.error_desc = "BAD STATUS ["+ response.status+"]";
            return responseJson;
        }

    } catch (error) {
        resp.error = true;
        resp.error_desc = error;
        return resp;
    }
}

export const unbarring = async (data) => {
    let resp;
    try {
        const response = await fetch('https://apps-ws.spot1.mx/api/api-router', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "api": "reactivateTraficEnt",
                "data_request": {
                    accessToken: data.accessToken,
                    msisdn: data.msisdn
                }
            })
        })

        const responseJson = await response.json()

        if (response.status == 200) {
            responseJson.error = false;
            return responseJson;
        } else {
            responseJson.error = true;
            responseJson.error_desc = "BAD STATUS ["+ response.status+"]";
            return responseJson;
        }

    } catch (error) {
        resp.error = true;
        resp.error_desc = error;
        return resp;
    }
}

export const deactivate = async (data) => {
    let resp;
    try {
        const response = await fetch('https://apps-ws.spot1.mx/api/api-router', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "api": "suspendTraficEntSal",
                "data_request": {
                    accessToken: data.accessToken,
                    msisdn: data.msisdn
                }
            })
        })

        const responseJson = await response.json()

        if (response.status == 200) {
            responseJson.error = false;
            return responseJson;
        } else {
            responseJson.error = true;
            responseJson.error_desc = "BAD STATUS ["+ response.status+"]";
            return responseJson;
        }

    } catch (error) {
        resp.error = true;
        resp.error_desc = error;
        return resp;
    }
}

export const reactivate = async (data) => {
    let resp;
    try {
        const response = await fetch('https://apps-ws.spot1.mx/api/api-router', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "api": "reactivateTraficEntSal",
                "data_request": {
                    accessToken: data.accessToken,
                    msisdn: data.msisdn
                }
            })
        })

        const responseJson = await response.json()

        if (response.status == 200) {
            responseJson.error = false;
            return responseJson;
        } else {
            responseJson.error = true;
            responseJson.error_desc = "BAD STATUS ["+ response.status+"]";
            return responseJson;
        }

    } catch (error) {
        resp.error = true;
        resp.error_desc = error;
        return resp;
    }
}

export const deactivate4Ever= async (data) => {
    let resp;
    try {
        const response = await fetch('https://apps-ws.spot1.mx/api/api-router', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "api": "deactivateForEver",
                "data_request": {
                    accessToken: data.accessToken,
                    msisdn: data.msisdn
                }
            })
        })

        const responseJson = await response.json()

        if (response.status == 200) {
            responseJson.error = false;
            return responseJson;
        } else {
            responseJson.error = true;
            responseJson.error_desc = "BAD STATUS ["+ response.status+"]";
            return responseJson;
        }

    } catch (error) {
        resp.error = true;
        resp.error_desc = error;
        return resp;
    }
}

export const rechargeMSISDN= async (data) => {
    let resp;
    try {
        const response = await fetch('https://apps-ws.spot1.mx/api/api-router', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "api": "product_purchase_SYSTEM_LITE",
                "data_request": {
                    accessToken: data.accessToken,
                    msisdn: data.msisdn,
                    offerID: data.offerID,
                    rate_id: data.rate_id
                }
            })
        })

        const responseJson = await response.json()

        if (response.status == 200) {
            responseJson.error = false;
            return responseJson;
        } else {
            responseJson.error = true;
            responseJson.error_desc = "BAD STATUS ["+ response.status+"]";
            return responseJson;
        }

    } catch (error) {
        resp.error = true;
        resp.error_desc = error;
        return resp;
    }
}