import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom";
import { SideBar } from "../components/SideBar"
import "../styles/generic.css"
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import io from 'socket.io-client';

const socket = io('https://systemlite.spot1.mx');

export const Home = ({ logged, setLogged, user_name, setUsername, user_lastname, setUserLastname, user_company, setUserCompany, user_email, setUserEmail }) => {
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [recargas, setRecargas] = useState('0');
  const [activaciones, setActivaciones] = useState('0');

  const [datos, setDatos] = useState(0);
  const [last_update_datos, setLastUpdateDatos] = useState(0);
  const [sms, setSMS] = useState(0);
  const [last_update_sms, setLastUpdateSMS] = useState(0);
  const [voz, setVoz] = useState(0);
  const [last_update_voz, setLastUpdateVoz] = useState(0);

  const [response, setResponse] = useState('');
  const [decremento, setDecremento] = useState(10);
  const [waiting, setWaiting] = useState(true);
  let msgSocket = {};
  let msgSocket1st = {};
  let counter = 0;
  let decremental = 15;

  useEffect(() => {
    const socket = io('https://systemlite.spot1.mx');
    socket.on('connect', () => {
        // console.log('Conectado al servidor');
        msgSocket1st = {
            company_id: user_company
        }
        setWaiting(true)
        socket.emit('message', msgSocket1st);
  
        const interval = setInterval(() => {
            counter+=1;
            decremental-=1;
            setDecremento(decremental);

            if(counter == 15){
                msgSocket = {
                  company_id: user_company
                }
                setWaiting(true)
                socket.emit('message', msgSocket);
            }
            // socket.emit('mensaje', 'Hola desde el cliente React Native');
        }, 1000); // Envía un mensaje cada 5 segundos
  
        // Manejar mensajes recibidos del servidor
        socket.on('message', message => {
            // console.log('Mensaje recibido desde el servidor:', message);
            setResponse(message);
            setRecargas(message.recargas[0].recargas);
            setActivaciones(message.activaciones[0].activaciones);

            if(message.cdrs && Object.keys(message.cdrs).length > 0){
              setDatos(((message.cdrs.data) / 1024).toFixed(2));
              setLastUpdateDatos(formatDate(message.cdrs.last_update_data));
              setSMS(message.cdrs.sms);
              setLastUpdateSMS(formatDate(message.cdrs.last_update_sms));
              setVoz(message.cdrs.voice);
              setLastUpdateVoz(formatDate(message.cdrs.last_update_voice));
            }else{
              console.log("NO HAY NADA EN EL PARAMETRO CDRS")
            }

            counter = 0;
            decremental = 15;
            setDecremento(decremental)
            setWaiting(false)
        });
  
        // Detener el intervalo cuando se desconecta
        socket.on('disconnect', () => {
          console.log('Desconectado del servidor');
          clearInterval(interval);
        });
    });

    return () => {
      socket.off('message');  // Elimina los oyentes del socket
      socket.disconnect();    // Desconecta el socket
  };

    
}, [])

  useEffect(() => {
    const verifyLogged = () => {
      if(!logged){
        navigate('/');
      }
    }

    verifyLogged();
  }, [logged]);

  function formatDate(fecha){
    const dateUtc = new Date(fecha);
    const formattedDate = new Intl.DateTimeFormat('es-ES', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false // 24 horas
    }).format(dateUtc);

    return formattedDate;
  }



    const data = {
        labels: ['Datos', 'SMS', 'Minutos'],
        datasets: [
          {
            label: 'Dataset 1',
            data: [12, 19, 3],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
            ],
            borderWidth: 1,
          },
        ],
      };

      const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                const label = context.label || '';
                const value = context.raw || 0;
                return `${label}: ${value}`;
              },
            },
          },
        },
      };

    return(
    <>
    <div className="container">
        <SideBar logged={logged} setLogged={setLogged} user_name={user_name} setUsername={setUsername} user_lastname={user_lastname} setUserLastname={setUserLastname} user_company={user_company} setUserCompany={setUserCompany} user_email={user_email} setUserEmail={setUserEmail} />
        <div className="content">
            <div className="col-12">
                <h2>Bienvenido!</h2>
                <div className="row">
                    <div class="col-6 mb-4">
                      <div class="card shadow">
                        <div class="card-body">
                          <div class="row align-items-center">
                            <div class="col-3 text-center">
                              <span class="circle circle-sm bg-primary text-white d-flex justify-content-center">
                                <FontAwesomeIcon icon={'signal'} />
                              </span>
                            </div>
                            <div class="col pr-0">
                              <p class="small text-muted mb-0">Recargas</p>
                              <span class="h3 mb-0">{recargas}</span>
                              <span class="small text-white"> Hoy</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-6 mb-4">
                      <div class="card shadow">
                        <div class="card-body">
                          <div class="row align-items-center">
                            <div class="col-3 text-center">
                              <span class="circle circle-sm bg-primary text-white d-flex justify-content-center">
                                <FontAwesomeIcon icon={'square-plus'} />
                              </span>
                            </div>
                            <div class="col pr-0">
                              <p class="small text-muted mb-0">Activaciones</p>
                              <span class="h3 text-white mb-0">{activaciones}</span>
                              <span class="small text-white"> Hoy</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-4 mb-4">
                      <div class="card shadow">
                        <div class="card-body">
                          <div class="row align-items-center">
                            <div class="col-3 text-center">
                              <span class="circle circle-sm bg-primary text-white d-flex justify-content-center">
                                <FontAwesomeIcon icon={'signal'} />
                              </span>
                            </div>
                            <div class="col pr-0">
                              <p class="small text-muted mb-0">Datos</p>
                              <p class="small text-muted mb-0">Ult. vez: {last_update_datos}</p>
                              <span class="h3 mb-0">{datos}</span>
                              <span class="small text-white"> GB</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-4 mb-4">
                      <div class="card shadow">
                        <div class="card-body">
                          <div class="row align-items-center">
                            <div class="col-3 text-center">
                              <span class="circle circle-sm bg-primary text-white d-flex justify-content-center">
                                <FontAwesomeIcon icon={'phone-volume'} />
                              </span>
                            </div>
                            <div class="col pr-0">
                              <p class="small text-muted mb-0">Minutos</p>
                              <p class="small text-muted mb-0">Ult. vez: {last_update_voz}</p>
                              <span class="h3 text-white mb-0">{voz}</span>
                              <span class="small text-white"> Min</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-4 mb-4">
                      <div class="card shadow">
                        <div class="card-body">
                          <div class="row align-items-center">
                            <div class="col-3 text-center">
                              <span class="circle circle-sm bg-primary text-white d-flex justify-content-center">
                                <FontAwesomeIcon icon={'comment-sms'} />
                              </span>
                            </div>
                            <div class="col pr-0">
                              <p class="small text-muted mb-0">SMS</p>
                              <p class="small text-muted mb-0">Ult. vez: {last_update_sms}</p>
                              <span class="h3 text-white mb-0">{sms}</span>
                              <span class="small text-white"> Sms</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                {/* <Pie data={data} options={options} /> */}
            </div>
        </div>
    </div>
    </>
    )}